module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"manualLoad":true,"prodKey":"YnHrVrNrxr46yDbhXJHHQdOuDIJhYczE","devKey":"sYtHhSjFLoi5f1TvZzAAWQ8osZfQi6sv","host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"trackPage":false},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-autonomy/gatsby-browser.js'),
      options: {"plugins":[],"omsConfig":{"brandName":"omorpho","storeId":"2","omsId":"042d2abc-d4d1-4ac7-b2d5-2622bb884e11","tenantId":"96dcbe47-d63b-4e5c-b064-00cf3ec1afbb","domain":"https://omorpho.assembly-api.com/"},"contentfulConfig":{"spaceId":"yi7bbhhdwan5","accessToken":"sXRkHWrWVqcOG6tn34ECjwwZjwWu9HBcXZvpP_9etM4","environment":"master"},"siteMetadata":{"title":"Omorpho","siteUrl":"https://www.omorpho.com","description":"Gravity is your superpower","social":{"facebook":"https://www.facebook.com/omorphogravity/","instagram":"https://www.instagram.com/omorpho","twitter":"https://twitter.com/omorpho_fit","tiktok":"https://www.tiktok.com/@omorpho","youtube":"https://www.youtube.com/channel/UC3DCkaJzqAGTvhyNNAPKKWg","linkedin":"https://www.linkedin.com/company/omorpho"}},"sitemapConfig":{"excludes":["/cart","/order/confirmation","/products","/kits","/finalize-login"]},"authConfig":{"magic":{"apiKey":"pk_live_6A1709AE70ABECA5"}},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},"segmentConfig":{"prodKey":"YnHrVrNrxr46yDbhXJHHQdOuDIJhYczE","devKey":"sYtHhSjFLoi5f1TvZzAAWQ8osZfQi6sv","host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.omorpho.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
