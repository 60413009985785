exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-activities-tsx": () => import("./../../../src/pages/activities.tsx" /* webpackChunkName: "component---src-pages-activities-tsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-[number]-index-tsx": () => import("./../../../src/pages/checkout/[number]/index.tsx" /* webpackChunkName: "component---src-pages-checkout-[number]-index-tsx" */),
  "component---src-pages-finalize-login-js": () => import("./../../../src/pages/finalize-login.js" /* webpackChunkName: "component---src-pages-finalize-login-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-confirmation-[number]-[id]-js": () => import("./../../../src/pages/order/confirmation/[number]/[id].js" /* webpackChunkName: "component---src-pages-order-confirmation-[number]-[id]-js" */),
  "component---src-pages-order-confirmation-index-js": () => import("./../../../src/pages/order/confirmation/index.js" /* webpackChunkName: "component---src-pages-order-confirmation-index-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-shop-all-tsx": () => import("./../../../src/pages/shop-all.tsx" /* webpackChunkName: "component---src-pages-shop-all-tsx" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-activity-tsx": () => import("./../../../src/templates/activity.tsx" /* webpackChunkName: "component---src-templates-activity-tsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-kit-tsx": () => import("./../../../src/templates/kit.tsx" /* webpackChunkName: "component---src-templates-kit-tsx" */),
  "component---src-templates-press-list-js": () => import("./../../../src/templates/press-list.js" /* webpackChunkName: "component---src-templates-press-list-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */)
}

